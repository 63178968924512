/*
// Font Icons
// ------------------------------------------------ */

//==== Base ====//
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: $font-icons-name !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

//==== Chevrons ====//
// 24 xl
// 20 lg
// 14 md
// 9 sm
[class^="chevron-"], [class*=" chevron-"] {
    display: inline-block;

    &::before {
        // position: relative;
        // top: 50%;
        display: inline-block;
        border-color: color(blue);
        border-style: solid;
        transform: rotate(-45deg);
        content: '';
    }
    &.right::before {
        transform: rotate(45deg);
    }
    &.bottom::before {
        transform: rotate(135deg);
    }
    &.left::before {
        left: 0.25em;
        transform: rotate(-135deg);
    }
}
.chevron-sm::before { @include chevron-size(9px); }
.chevron-md::before { @include chevron-size(14px); }
.chevron-lg::before { @include chevron-size(20px); }
.chevron-xl::before { @include chevron-size(24px); }

// Icons
.icon-sandals-foundation-logo-new:before {
  content: "\e9e2";
}
.icon-contact:before {
  content: "\e9d3";
}
.icon-eye_password-hidden:before {
  content: "\e9d4";
}
.icon-eye_password-shown:before {
  content: "\e9d5";
}
.icon-log-out:before {
  content: "\e9d6";
}
.icon-offers:before {
  content: "\e9d8";
}
.icon-overview:before {
  content: "\e9d9";
}
.icon-profile:before {
  content: "\e9db";
}
.icon-referals:before {
  content: "\e9de";
}
.icon-rewards:before {
  content: "\e9df";
}
.icon-sign-in:before {
  content: "\e9e0";
}
.icon-trips:before {
  content: "\e9e1";
}
.icon-logo-sandals-select:before {
  content: "\e9d2";
}
.icon-beach-chair:before {
  content: "\e9bb";
}
.icon-heart:before {
  content: "\e9bc";
}
.icon-inspire:before {
  content: "\e9ce";
}
.icon-loyalty:before {
  content: "\e9d1";
}
.icon-plus-sign:before {
  content: "\e9b1";
}
.icon-minus-sign:before {
  content: "\e9b9";
}
.icon-aisle-to-isle-stacked-1:before {
  content: "\e9ad";
}
.icon-leaf:before {
  content: "\e9ae";
}
.icon-aisle-to-isle:before {
  content: "\e9af";
}
.icon-aisle-to-isle_with-tagline_stacked:before {
  content: "\e9b0";
}
.icon-vip:before {
  content: "\e9ac";
}
.icon-SF_Logo_Black100:before {
  content: "\e9a4";
}
.icon-logo-Sandals-new:before {
  content: "\e992";
}
.icon-logo-Beaches-new:before {
  content: "\e993";
}
.icon-accommodations:before {
  content: "\e900";
}
.icon-uniE901:before {
  content: "\e901";
}
.icon-uniE902:before {
  content: "\e902";
}
.icon-button-arrow-down:before {
  content: "\e903";
}
.icon-link-arrow-beaches:before {
  content: "\e904";
}
.icon-bag-beaches:before {
  content: "\e905";
}
.icon-caribbeantour-beaches:before {
  content: "\e906";
}
.icon-chootrain-beaches:before {
  content: "\e907";
}
.icon-kidscamp-beaches:before {
  content: "\e908";
}
.icon-arrow-right:before {
  content: "\e909";
}
.icon-calendar:before {
  content: "\e90a";
}
.icon-camera:before {
  content: "\e90b";
}
.icon-chat-with-us:before {
  content: "\e90c";
}
.icon-landsports-beaches:before {
  content: "\e90d";
}
.icon-chat-with-us-es:before {
  content: "\e90e";
}
.icon-check:before {
  content: "\e90f";
}
.icon-red-lane-spa-beaches:before {
  content: "\e910";
}
.icon-compare:before {
  content: "\e911";
}
.icon-dropdown-arrows:before {
  content: "\e912";
}
.icon-room-service-beaches:before {
  content: "\e913";
}
.icon-scuba-beaches:before {
  content: "\e914";
}
.icon-voila-beaches:before {
  content: "\e915";
}
.icon-waterpark-beaches:before {
  content: "\e916";
}
.icon-facebook:before {
  content: "\e917";
}
.icon-watersports-beaches:before {
  content: "\e918";
}
.icon-globe:before {
  content: "\e919";
}
.icon-google-plus:before {
  content: "\e91a";
}
.icon-instagram:before {
  content: "\e91b";
}
.icon-beaches:before {
  content: "\e91c";
}
.icon-fowl-cay:before {
  content: "\e91d";
}
.icon-global-gourmet:before {
  content: "\e91e";
}
.icon-grand-pineapple:before {
  content: "\e91f";
}
.icon-jamaican-villas:before {
  content: "\e920";
}
.icon-sandals:before {
  content: "\e921";
}
.icon-sandals-foundation:before {
  content: "\e922";
}
.icon-marker-transparent:before {
  content: "\e923";
}
.icon-plane:before {
  content: "\e924";
}
.icon-planner:before {
  content: "\e925";
}
.icon-reading:before {
  content: "\e926";
}
.icon-restaurants:before {
  content: "\e927";
}
.icon-search:before {
  content: "\e928";
}
.icon-specials:before {
  content: "\e929";
}
.icon-twitter:before {
  content: "\e92a";
}
.icon-weddings:before {
  content: "\e92b";
}
.icon-star:before {
  content: "\e92c";
}
.icon-accommodations-second:before {
  content: "\e92d";
}
.icon-info:before {
  content: "\e92e";
}
.icon-reset:before {
  content: "\e92f";
}
.icon-close:before {
  content: "\e930";
}
.icon-butler-elite:before {
  content: "\e931";
}
.icon-bar:before {
  content: "\e932";
}
.icon-pool:before {
  content: "\e933";
}
.icon-butler:before {
  content: "\e934";
}
.icon-restaurants-second:before {
  content: "\e935";
}
.icon-private-transfers:before {
  content: "\e936";
}
.icon-room-service:before {
  content: "\e937";
}
.icon-wi-fi:before {
  content: "\e938";
}
.icon-grid-view:before {
  content: "\e939";
}
.icon-list-view:before {
  content: "\e93a";
}
.icon-button-arrow-next:before {
  content: "\e93b";
}
.icon-refine:before {
  content: "\e93c";
}
.icon-button-arrow-prev:before {
  content: "\e93d";
}
.icon-cabana:before {
  content: "\e93e";
}
.icon-entertainment:before {
  content: "\e93f";
}
.icon-landsports:before {
  content: "\e940";
}
.icon-red-lane-spa:before {
  content: "\e941";
}
.icon-running:before {
  content: "\e942";
}
.icon-scuba:before {
  content: "\e943";
}
.icon-tours:before {
  content: "\e944";
}
.icon-watersports:before {
  content: "\e945";
}
.icon-yacht:before {
  content: "\e946";
}
.icon-download:before {
  content: "\e947";
}
.icon-marker-solid:before {
  content: "\e948";
}
.icon-pinterest:before {
  content: "\e949";
}
.icon-soaking-tub:before {
  content: "\e94a";
}
.icon-youtube:before {
  content: "\e94b";
}
.icon-bag:before {
  content: "\e94c";
}
.icon-butler-transparent:before {
  content: "\e94d";
}
.icon-call:before {
  content: "\e94e";
}
.icon-card:before {
  content: "\e94f";
}
.icon-laundry:before {
  content: "\e950";
}
.icon-lock:before {
  content: "\e951";
}
.icon-luggage:before {
  content: "\e952";
}
.icon-medical:before {
  content: "\e953";
}
.icon-logo-concierge:before {
  content: "\e954";
}
.icon-money:before {
  content: "\e955";
}
.icon-passport:before {
  content: "\e956";
}
.icon-safe:before {
  content: "\e957";
}
.icon-smartphone:before {
  content: "\e958";
}
.icon-transfers:before {
  content: "\e959";
}
.icon-weather:before {
  content: "\e95a";
}
.icon-wheelchair:before {
  content: "\e95b";
}
.icon-hanger:before {
  content: "\e95c";
}
.icon-outlet:before {
  content: "\e95d";
}
.icon-foundation:before {
  content: "\e95e";
}
.icon-shopping:before {
  content: "\e95f";
}
.icon-candlelight:before {
  content: "\e960";
}
.icon-logo-together-nest:before {
  content: "\e961";
}
.icon-departure-tax:before {
  content: "\e962";
}
.icon-red-lane-spa-logo:before {
  content: "\e963";
}
.icon-shape:before {
  content: "\e964";
}
.icon-logo-concierge-service:before {
  content: "\e965";
}
.icon-logo-beaches-top:before {
  content: "\e966";
}
.icon-golf-beaches:before {
  content: "\e967";
}
.icon-play-beach:before {
  content: "\e968";
}
.icon-currency:before {
  content: "\e969";
}
.icon-memorial:before {
  content: "\e96a";
}
.icon-together-nest-no-txt:before {
  content: "\e96b";
}
.icon-stay-and-play:before {
  content: "\e96c";
}
.icon-sipsip-hooray:before {
  content: "\e96d";
}
.icon-tranquility-sleep:before {
  content: "\e96e";
}
.icon-golf-shield-logo:before {
  content: "\e96f";
}
.icon-logo-taylormade:before {
  content: "\e970";
}
.icon-baby-sitting:before {
  content: "\e971";
}
.icon-logo-concierge-simple:before {
  content: "\e972";
}
.icon-ways-to-save:before {
  content: "\e973";
}
.icon-golf-lessons:before {
  content: "\e974";
}
.icon-play-button:before {
  content: "\e975";
}
.icon-blue-mountain:before {
  content: "\e976";
}
.icon-DAN-logo:before {
  content: "\e977";
}
.icon-PADI-eLearning-logo:before {
  content: "\e978";
}
.icon-button-next:before {
  content: "\e979";
}
.icon-button-prev:before {
  content: "\e97a";
}
.icon-candlelight-dinner:before {
  content: "\e97b";
}
.icon-activities-snuba:before {
  content: "\e97c";
}
.icon-spa-teens:before {
  content: "\e97d";
}
.icon-earthguard-energy:before {
  content: "\e97e";
}
.icon-earthguard-staff:before {
  content: "\e97f";
}
.icon-earthguard-substances:before {
  content: "\e980";
}
.icon-earthguard-tours:before {
  content: "\e981";
}
.icon-earthguard-waste:before {
  content: "\e982";
}
.icon-earthguard-water:before {
  content: "\e983";
}
.icon-logo-redlane-spa-new:before {
  content: "\e984";
}
.icon-washer-dryer:before {
  content: "\e985";
}
.icon-pinterest-circle:before {
  content: "\e986";
}
.icon-icon-styrofoam-cup:before {
  content: "\e987";
}
.icon-double-arrow:before {
  content: "\e988";
}
.icon-backpack:before {
  content: "\e989";
}
.icon-logo-sandals-earthguard:before {
  content: "\e98a";
}
.icon-seedling:before {
  content: "\e98b";
}
.icon-signature:before {
  content: "\e98c";
}
.icon-coral:before {
  content: "\e98d";
}
.icon-tooth-paste:before {
  content: "\e98e";
}
.icon-padi:before {
  content: "\e98f";
}
.icon-community:before {
  content: "\e990";
}
.icon-school:before {
  content: "\e991";
}
.icon-free-week:before {
  content: "\e994";
}
.icon-dollar-points:before {
  content: "\e995";
}
.icon-recognition:before {
  content: "\e996";
}
.icon-sandals-select:before {
  content: "\e997";
}
.icon-american-sweepstakes:before {
  content: "\e998";
}
.icon-bridal-number:before {
  content: "\e999";
}
.icon-fitness-centers:before {
  content: "\e99a";
}
.icon-red-lane-weddings:before {
  content: "\e99b";
}
.icon-spa-treatments:before {
  content: "\e99c";
}
.icon-alert:before {
  content: "\e99d";
}
.icon-before-you-arrive:before {
  content: "\e99e";
}
.icon-check-in-online:before {
  content: "\e99f";
}
.icon-pay-balance:before {
  content: "\e9a0";
}
.icon-digital-album:before {
  content: "\e9a1";
}
.icon-bowling:before {
  content: "\e9a2";
}
.icon-exchange:before {
  content: "\e9a3";
}
.icon-five-star:before {
  content: "\e9a5";
}
.icon-fork:before {
  content: "\e9a6";
}
.icon-golf:before {
  content: "\e9a7";
}
.icon-island:before {
  content: "\e9a8";
}
.icon-over-the-water:before {
  content: "\e9a9";
}
.icon-pool-small:before {
  content: "\e9aa";
}
.icon-bar-second:before {
  content: "\e9ab";
}
.icon-island-routes:before {
  content: "\e9b2";
}
.icon-circle-loader:before {
  content: "\e9b3";
}
.icon-beer-wine:before {
  content: "\e9b4";
}
.icon-premium-spirits:before {
  content: "\e9b5";
}
.icon-choice-premium-spirits:before {
  content: "\e9b6";
}
.icon-concierge:before {
  content: "\e9b7";
}
.icon-rolls-royce:before {
  content: "\e9b8";
}
.icon-dive:before {
  content: "\e9ba";
}
.icon-liv-plus:before {
  content: "\e9bd";
}
.icon-arrow:before {
  content: "\e9be";
}
.icon-landscape-orientation:before {
  content: "\e9bf";
}
.icon-saved-wishlist:before {
  content: "\e9c0";
}
.icon-share:before {
  content: "\e9c1";
}
.icon-zoom-reset:before {
  content: "\e9c2";
}
.icon-ticket:before {
  content: "\e9c3";
}
.icon-hand-pointer:before {
  content: "\e9c4";
}
.icon-diver:before {
  content: "\e9c5";
}
.icon-drink:before {
  content: "\e9c6";
}
.icon-laid-back:before {
  content: "\e9c7";
}
.icon-meeting:before {
  content: "\e9c8";
}
.icon-socializer:before {
  content: "\e9c9";
}
.icon-tennis-transparent:before {
  content: "\e9ca";
}
.icon-sandals-tag:before {
  content: "\e9cb";
}
.icon-beaches-tag:before {
  content: "\e9cc";
}
.icon-expand:before {
  content: "\e9cd";
}
.icon-play:before {
  content: "\e9cf";
}
.icon-pause:before {
  content: "\e9d0";
}
.icon-mondavi:before {
  content: "\e9d7";
}
.icon-top-5-scuba:before {
  content: "\e9da";
}
.icon-cube:before {
  content: "\e9dd";
}
.icon-vrx:before {
  content: "\e9dc";
}
/* Icons updated ends*/