@media (max-width:768px) {
	body.date-open {
		overflow: hidden
	}
}

.help-text {
	margin-top: 16px
}

.help-text.error {
	color: #d40922
}

.rdatez {
	position: relative;
	display: inline-block
}

.rdatez a,
.rdatez a:link,
.rdatez a:visited {
	text-decoration: none
}

.rdatez.error .rdatez-picker {
	color: #666
}

.rdatez-centered .rdatez-picker,
.rdatez-centered .rdatez-picker:after,
.rdatez-centered .rdatez-picker:before {
	left: 50%;
	-webkit-transform: translate(-50%);
	transform: translate(-50%)
}

@media (max-width:768px) {
	.rdatez-centered .rdatez-picker {
		-webkit-transform: none;
		transform: none;
		left: 0
	}
}

.rdatez .cal-icon {
	position: absolute;
	top: 50%;
	right: 10px;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	width: 18px;
	height: 18px;
	fill: #3e3e3e
}

.rdatez .cal-icon:hover {
	cursor: pointer
}

.rdatez-right .rdatez-picker {
	left: auto;
	right: 0
}

.rdatez-right .rdatez-picker:after,
.rdatez-right .rdatez-picker:before {
	left: auto;
	right: 12px
}

.rdatez-picker {
	margin-top: 16px;
	border: 1px solid #ccc;
	border-radius: 4px;
	padding: 16px;
	position: absolute;
	top: 0;
	left: 0;
	background: #fff;
	width: 100%;
	box-shadow: 0 5px 10px rgba(0, 0, 0, .05);
	z-index: 200
}

@media (max-width:768px) {
	.rdatez-picker {
		overflow: scroll
	}
}

@media (min-width:769px) {
	.rdatez-picker {
		max-width: 400px;
		min-width: 400px
	}
}

.rdatez-picker.multi-cal {
	min-width: auto;
	max-width: auto
}

@media (min-width:769px) {
	.rdatez-picker.multi-cal {
		min-width: 700px;
		max-width: 700px
	}
}

@media (max-width:768px) {
	.rdatez-picker.multi-cal .rdatez-calendar {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column
	}
}

.rdatez-picker.multi-cal .rdatez-calendar>div:first-child {
	margin-right: 16px
}

@media (max-width:768px) {
	.rdatez-picker.multi-cal .rdatez-calendar>div {
		margin: 0 0 16px
	}
}

.rdatez-picker>div {
	position: relative
}

@media (max-width:768px) {
	.rdatez-picker {
		position: fixed;
		margin-top: 0;
		border-radius: 0;
		border: none;
		top: 0!important;
		right: 0;
		bottom: 0;
		left: 0;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: start;
		-ms-flex-align: start;
		align-items: flex-start;
		min-width: auto
	}
}

.rdatez-picker:before {
	position: absolute;
	top: -25px;
	left: 12px;
	content: "";
	border: 12px solid transparent;
	border-bottom: 12px solid #ccc
}

.rdatez-picker:after {
	position: absolute;
	top: -24px;
	left: 12px;
	content: "";
	border: 12px solid transparent;
	border-bottom: 12px solid #fff
}

.rdatez-mobile-close {
	display: none;
	position: absolute;
	top: -1px;
	right: 10px;
	z-index: 100;
	background: none;
	border: none
}

.rdatez-mobile-close>svg {
	fill: #000;
	height: 32px;
	width: 32px
}

@media (max-width:768px) {
	.rdatez-mobile-close {
		display: block
	}
}

@media (max-width:768px) {
	.rdatez-header {
		text-align: left;
		position: fixed;
		left: 0;
		right: 0;
		padding: 0 16px;
		background: #fff;
		box-shadow: 0 5px 10px rgba(0, 0, 0, .05);
		z-index: 999
	}
}

.rdatez-btn {
	background: #fff;
	border: 1px solid rgba(0, 0, 0, .2);
	border-radius: 4px;
	text-align: center;
	height: 36px;
	width: 40px;
	padding: 0 8px;
	position: absolute;
	top: 0;
	right: 0;
	cursor: pointer
}

@media (max-width:768px) {
	.rdatez-btn {
		position: static;
		margin-bottom: 16px;
		margin-right: 8px
	}
}

.rdatez-btn>svg {
	fill: #000;
	width: 18px;
	height: 18px;
	vertical-align: top
}

.rdatez-btn-year {
	left: 48px
}

.rdatez-btn-today {
	right: 48px
}

.rdatez-btn-prev {
	left: 0
}

.rdatez-month-days {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap
}

.rdatez-calendar,
.rdatez-month-days {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex
}

.rdatez-calendar {
	overflow: hidden;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between
}

@media (max-width:768px) {
	.rdatez-calendar {
		margin-top: 80px
	}
}

.rdatez-calendar-title {
	font-weight: 800;
	text-align: center;
	padding: 10px 0;
	color: #666
}

.rdatez-calendar>div {
	width: 100%
}

.rdatez-calendar-month,
.rdatez-calendar-year {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	position: absolute;
	padding: 16px;
	top: 50px;
	left: 0;
	bottom: 0;
	background: #fff;
	width: 100%;
	border-top: 1px solid rgba(0, 0, 0, .1);
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center
}

@media (max-width:768px) {
	.rdatez-calendar-month,
	.rdatez-calendar-year {
		position: fixed;
		height: 100%
	}
}

.rdatez-daysofweek {
	width: 100%;
	margin: 16px 0 8px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	color: #666
}

.rdatez-daysofweek>span {
	width: 14.285%;
	font-size: 11px;
	text-align: center
}

.rdatez-day {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	background: none;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	width: 14.285%;
	height: 40px;
	border-right: 1px solid rgba(0, 0, 0, .1);
	border-bottom: 1px solid rgba(0, 0, 0, .1);
	position: relative;
	transition: none;
	cursor: pointer
}

.rdatez-day:after {
	display: none;
}

.rdatez-day.weekday-1 {
	border-left: 1px solid rgba(0, 0, 0, .1);
}

.rdatez-day:nth-last-child(-n+8) {
	border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.rdatez-day:last-child {
	border-top: 0;
}

.rdatez-day-spacer {
	border-right: 1px solid rgba(0, 0, 0, .1);
	border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.rdatez-day-spacer.weekday-1 {
	display: none;
}

.rdatez-day-spacer.weekday-2 {
	width: 14.285%;
}

.rdatez-day-spacer.weekday-3 {
	width: 28.57%;
}

.rdatez-day-spacer.weekday-4 {
	width: 42.855%;
}

.rdatez-day-spacer.weekday-5 {
	width: 57.14%;
}

.rdatez-day-spacer.weekday-6 {
	width: 71.425%;
}

.rdatez-day-spacer.weekday-0 {
	width: 85.71%;
}

.rdatez .starts-on-0 .rdatez-day:nth-child(-n+2),
.rdatez .starts-on-1 .rdatez-day:nth-child(-n+8),
.rdatez .starts-on-2 .rdatez-day:nth-child(-n+7),
.rdatez .starts-on-3 .rdatez-day:nth-child(-n+6),
.rdatez .starts-on-4 .rdatez-day:nth-child(-n+5),
.rdatez .starts-on-5 .rdatez-day:nth-child(-n+4),
.rdatez .starts-on-6 .rdatez-day:nth-child(-n+3) {
	border-top: 1px solid rgba(0, 0, 0, .1);
}

.rdatez-month {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	background: none;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	width: 25%;
	height: 40px;
	position: relative;
	transition: none
}

.rdatez-month:after {
	display: none;
}

.rdatez-month.selected-day,
.rdatez-month:hover {
	cursor: pointer;
	background: rgba(0, 0, 0, .05);
}

.rdatez-year {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	background: none;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	width: 25%;
	height: 40px;
	position: relative;
	transition: none
}

.rdatez-year:after {
	display: none;
}

.rdatez-year.selected-day,
.rdatez-year:hover {
	cursor: pointer;
	background: rgba(0, 0, 0, .05);
}

.rdatez-day {
	color: #666;
}

.rdatez-day:hover {
	background: rgba(0, 0, 0, .1);
}

.rdatez-day.past-day {
	color: rgba(0, 0, 0, .4);
}

.disallow-future a.rdatez-day,
.disallow-past a.rdatez-day {
	color: #666;
}

.disallow-after-end a.rdatez-day.after-end,
.disallow-before-start a.rdatez-day.before-start,
.disallow-future a.rdatez-day:not(.past-day):not(.today),
.disallow-past a.rdatez-day.past-day {
	color: rgba(0, 0, 0, .1);
	cursor: not-allowed;
}

.disallow-after-end a.rdatez-day.after-end:hover,
.disallow-before-start a.rdatez-day.before-start:hover,
.disallow-future a.rdatez-day:not(.past-day):not(.today):hover,
.disallow-past a.rdatez-day.past-day:hover {
	background: none;
}

.highlight-weekends a.rdatez-day.weekday-0,
.highlight-weekends a.rdatez-day.weekday-6 {
	background: rgba(0, 0, 0, .05);
}

.highlight-weekends a.rdatez-day.weekday-0:hover,
.highlight-weekends a.rdatez-day.weekday-6:hover {
	background: rgba(0, 0, 0, .1);
}

.disallow-future a.rdatez-day.today.selected-day,
.disallow-past a.rdatez-day.selected-day,
.disallow-past a.rdatez-day.today.selected-day,
.highlight-weekends a.rdatez-day.weekday-0.selected-day,
.highlight-weekends a.rdatez-day.weekday-6.selected-day,
.rdatez-day.past-day.selected-day,
a.selected-day {
	background: linear-gradient(to left bottom, #14e3ad 25%, #0bc091);
	border: none;
	color: #fff;
}