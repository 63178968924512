/*
// Colors Abstract
// ------------------------------------------------ */

// SASS Example: ============================
//                                          |
//      color: color(grey, darker-2);       |
//                                          |
// ==========================================

// Colors
$blue: (
  darker-47: #4a2ef4,
  darker-46: #035786,
  darker-45: #034166,
  darker-44: #00a7de,
  darker-43: #014166,
  darker-42: #01517f,
  darker-41: #006ebb,
  darker-40: #007fcb,
  darker-39: #016eba,
  darker-38: #0068b6,
  darker-37: #0d6987,
  darker-36: #004b87,
  darker-35: #001254,
  darker-34: #4b2ef8,
  darker-33: #606777,
  darker-32: #030312,
  darker-31: #0f0f22,
  darker-30: #47485d,
  darker-29: #222839,
  darker-28: #0cbbf4,
  darker-27: #55d3ff,
  darker-26: #17bdf3,
  darker-25: #00ace7,
  darker-24: #216ca3,
  darker-23: #2d7087,
  darker-22: #00436a,
  darker-21: #034267,
  darker-20: #004389,
  darker-19: #1bbcf8,
  darker-18: #004a80,
  darker-17: #6d8db2,
  darker-16: #121328,
  darker-15: #056c8c,
  darker-14: #05051d,
  darker-13: #121329,
  darker-12: #00aee6,
  darker-11: #004388,
  darker-10: #004d97,
  darker-9:  #00abe6,
  darker-8:  #0099ce,
  darker-7:  #1a1c1f,
  darker-6:  #062565,
  darker-5:  #021748,
  darker-4:  #001f44,
  darker-3:  #005798,
  darker-2:  #003a62,
  darker-1:  #094f98,
  dark:      #0070a1,
  default:   #00b8f4,
  lighter-1: #edfafe,
  lighter-2: #1dc5ff,
  lighter-3: #5695b3,
  lighter-4: #3c5a9a,
  lighter-5: #52cbff,
  lighter-6: #31c4ed,
  lighter-7: #6dacd2,
  lighter-8: #4784a7,
  lighter-9: #01abe6,
  lighter-10: #00a5ce,
  lighter-11: #01aee4,
  lighter-12: #62d2d3,
  lighter-13: #b0d5eb,
  lighter-14: #80c5f2,
  lighter-15: #ecfaff,
  lighter-16: #53d4d6,
  lighter-17: #c5dbe3,
  lighter-18: #7cb0cb,
  lighter-19: #0a5c79,
  lighter-20: #00b7f2,
  lighter-21: #0ab0e5,
  lighter-22: #7ad4f2,
  lighter-23: #19bff5,
  lighter-24: #1ac5fe,
  lighter-25: #cef1fd,
  lighter-26: #0192d8,
  lighter-27: #447DAA,
);

$grey: (
  darker-38: #858585,
  darker-37: #444,
  darker-36: #929497,
  darker-35: #1f1f1f,
  darker-34: #3b3e3e,
  darker-33: #d2d2d2,
  darker-32: #c3c3c3,
  darker-31: #15191b,
  darker-30: #1c2023,
  darker-29: #242424,
  darker-28: #4c4c4c,
  darker-27: #292929,
  darker-26: #2d2d2d,
  darker-25: #9b9592,
  darker-24: #b9b9b9,
  darker-23: #9b9b9b,
  darker-22: #797266,
  darker-21: #414141,
  darker-20: #0c1824,
  darker-19: #232323,
  darker-18: #494949,
  darker-17: #231f20,
  darker-16: #333333,
  darker-15: #484848,
  darker-14: #1d1d1d,
  darker-13: #3c3c3c,
  darker-12: #262524,
  darker-11: #13171a,
  darker-10: #2b2b2b,
  darker-9: #383838,
  darker-8: #464646,
  darker-7: #2f2f2f,
  darker-6: #7f7f7f,
  darker-5: #010101,
  darker-4: #4e4e4e,
  darker-3: #1a1a1a,
  darker-2: #373737,
  darker-1: #666,
  default: #797979,
  lighter-1: #acacac,
  lighter-2: #ddd,
  lighter-3: #ededed,
  lighter-4: #a1a1a1,
  lighter-5: #dbdbdb,
  lighter-6: #e0e0e0,
  lighter-7: #f5f5f5,
  lighter-8: #ccc,
  lighter-9: #eee,
  lighter-10: #cecece,
  lighter-11: #f6f6f6,
  lighter-12: #404040,
  lighter-13: #f6f4f1,
  lighter-14: #bababa,
  lighter-15: #555555,
  lighter-16: #84848a,
  lighter-17: #f3f3f8,
  lighter-18: #dfdfdf,
  lighter-19: #b8b8b8,
  lighter-20: #f1f1f0,
  lighter-21: #c5c5c5,
  lighter-22: #747474,
  lighter-23: #8d8d8d,
  lighter-24: #dcdcdc,
  lighter-25: #eaeaea,
  lighter-26: #f3f3f3,
  lighter-27: #f7f7f7,
  lighter-28: #d3d3d3,
  lighter-29: #e8e8e8,
  lighter-30: #d9d9d9,
  lighter-31: #dedede,
  lighter-32: #d7d7d7,
  lighter-33: #e1e1e1,
  lighter-34: #d6d6d6,
  lighter-35: #c8c8c8,
  lighter-36: #ebebeb,
  lighter-37: #e9e9e9,
  lighter-38: #f1f1f1,
  lighter-39: #fefefe,
  lighter-40: #f5f6f7,
  lighter-41: #eeeff0,
  lighter-42: #e2e2e2,
  lighter-43: #f8f6ee,
  lighter-44: #a7a7a7,
  light-45: #d2d2d2,
  lighter-46: #F4F4F4,
  lighter-47: #C4C4C4,
  light:    #979797,
  base:     #3e3e3e,
  dark:     #373737
);

$green: (
  darker-1: #579100,
  default: #88c240,
  lighter-1: #74a14d,
  lighter-2: #70bd4c,
  lighter-3: #9dc82d
);

$yellow: (
  darker-4: #ffba00,
  darker-3: #e1b900,
  darker-2: #f79e37,
  darker-1: #ea922c,
  default: #ffff00,
  lighter-1: #e2b63a,
  lighter-2: #eee4d7,
  lighter-3: #fffad1,
  lighter-4: #ffe993
);

$red: (
  darker-3: #f41975,
  darker-2: #d3055c,
  darker-1: #d02630,
  default: #ed1c24,
  lighter-1: #ff0000,
  lighter-2: #bd081c,
  lighter-3: #f68e92,
  lighter-4: #f40067,
  lighter-5: #fb076e,
  lighter-6: #fd83b7,
  lighter-7: #ed3200
);

$pink: (
  darker-4: #eb0063,
  darker-3: #251e34,
  darker-2: #ea148c,
  darker-1: #da547e,
  default: #d282aa,
  lighter-1: #f449f6,
  lighter-2: #f94afb,
  lighter-3: #ed0064,
  lighter-4: #f43284
);

$brown: (
  default: #97938b
);

$custom: (
  green-lighter-2: #d5ff65,
  green-lighter-1: #b0b579,
  green: #809a00,
  yellow: #f4c900,
  coral: #f48374,
  sapphire: #1f3d7c,
  emerald: #007367,
  diamond: #a9a9aa,
  pearl: #d9d2c5,
  cadetblue: #beefff,
  gold: #caaa81,
  gold-1: #c9a036,
  gold-2: #bb922e,
  gold-3: #a1771a,
  gold-4: #f5df9d,
  gold-5: #B1A18F,
  gold-6: #AEA191,
  orange: #fdb395
);

$shades: (
  black:        #000,
  white:        #FFF,
  transparent:  transparent
);

$colors: (
  shades: $shades,
  grey: $grey,
  green: $green,
  yellow: $yellow,
  blue: $blue,
  red: $red,
  pink: $pink,
  brown: $brown,
  custom: $custom
);
