.popup-datepicker {
    background: color(grey, darker-2);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: -100px;
    padding-bottom: 100px;
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    transition: all .2s;
    user-select: none;
    z-index: 1199;

    &-open {
        opacity: 1;
        visibility: visible;
    }

    .ppd-head {
        background: color(grey, darker-19);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 68px;
        z-index: 99;
    }

    .ppd-head-top {
        border-bottom: 1px solid color(grey, darker-28);
        overflow: hidden;

        button {
            appearance: none;
            background-color: transparent;
            border: none;
            color: color(shades, white);
            font-size: 16px;
            line-height: 1.25;
            display: block;
            float: right;
            padding: 3px 10px 5px;
        }
    }

    .ppd-head-title {
        color: color(shades, white);
        font-size: 21px;
        font-weight: 300;
        line-height: 31px;
        text-align: center;
        padding: 3px 0 5px;

        span {
            color: color(blue);
            font-weight: 600;
            text-transform: uppercase;
        }
    }

    .ppd-content {
        height: 100%;
        padding: 68px 0 0;
        overflow-y: auto;
    }

    .ui-datepicker {

        &.ui-datepicker-multi {
            table {
                width: 280px;
                margin: 0 auto;
                table-layout: fixed;
            }
        }

        .ui-datepicker-header {
            color: color(shades, white);
            font-size: 18px;
            font-weight: 300;
            line-height: 34px;
            position: static;

            .ui-datepicker-year {
                color: color(grey);
            }
        }

        .ui-datepicker-title {
            margin: 0;
        }

        .ui-datepicker-group {
            padding: 4px 13px;
        }

        .ui-datepicker-prev,
        .ui-datepicker-next {
            display: none;
        }
    }

    .ui-datepicker-calendar {
        th {
            color: color(blue);
            font-size: 11px;
            font-weight: 400;
            line-height: 2;
            padding: 0 .3em;
            text-transform: uppercase;
        }

        td {
            font-size: 15px;
            padding: 2px 4px;
            position: relative;

            a, span {
                color: color(shades, white);
                text-align: center;
                line-height: 24px;
                position: relative;
                padding: 4px .3em;
            }
        }

        .ui-state-disabled {
            a, span {
                color: color(grey, darker-1);
            }
        }
    }

    .dp-highlight {
        &:first-child {
            &::before {
                border-radius: 16px 0 0 16px;
            }

            &.dp-last-of-month {
                &::before {
                    border-radius: 16px;
                }
            }
        }

        &:last-child {
            &::before {
                border-radius: 0 16px 16px 0;
            }

            &.dp-first-of-month {
                &::before {
                    border-radius: 16px;
                }
            }
        }

        & + .dp-end {
            &::before {
                content: '';
                right: 4px;
                border-radius: 0 16px 16px 0;
            }
        }
    }

    .dp-first-of-month {
        &::before {
            border-radius: 16px 0 0 16px;
        }
    }

    .dp-last-of-month {
        &::before {
            border-radius: 0 16px 16px 0;
        }
    }

    .dp-highlight,
    .dp-end {
        &::before {
            content: '';
            background: color(blue, darker-23);
            position: absolute;
            left: 0;
            right: 0;
            height: 32px;
            top: 50%;
            margin-top: -16px;
            pointer-events: none;
        }
    }

    .dp-start {
        z-index: 2;

        &.ui-state-disabled span {
            color: color(shades, white);
        }

        & + .dp-end {
            &::before {
                content: '';
                right: 4px;
                border-radius: 0 16px 16px 0;
            }
        }

        & + .dp-highlight,
        & + .dp-end {
            &::before {
                left: -20px;
            }
        }

        &.ui-datepicker-current-day {
            &::before {
                content: none;
            }
        }

        .ui-state-default {
            background: color(blue);
            border-radius: 16px;
        }
    }

    .dp-end {
        &::before {
            content: none;
        }

        &.ui-state-disabled span {
            color: color(shades, white);
        }

        &.dp-first-of-month,
        &:first-child {
            &::before {
                left: 4px;
                border-radius: 16px;
            }
        }

        .ui-state-default {
            background: color(blue);
            border-radius: 16px;
        }
    }

    .ui-datepicker.ui-datepicker-multi {
        width: 100% !important;

        .ui-datepicker-group {
            width: 100%;
            float: none;
        }
    }
}