/*
// Mixins
// ------------------------------------------------ */

// Media Query helper, uses the $breakpoints map
@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  }

  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
    + "Please make sure it is defined in `$breakpoints` map.";
  }
}

@mixin respond-to-max($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (max-width: #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  }

  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
    + "Please make sure it is defined in `$breakpoints` map.";
  }
}

@mixin respond-within($breakpoint1, $breakpoint2) {
  @if map-has-key($breakpoints, $breakpoint1) and map-has-key($breakpoints, $breakpoint2) {
    @media (min-width: #{map-get($breakpoints, $breakpoint1)}) and (max-width: #{map-get($breakpoints, $breakpoint2)}) {
      @content;
    }
  }

  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint1}`/`#{$breakpoint2}`. "
    + "Please make sure it is defined in `$breakpoints` map.";
  }
}

// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons
@mixin button-variant($color, $background, $border, $hover: darken($background, 10%)) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &:focus,
  &.focus {
    color: $color;
    background-color: darken($background, 10%);
    border-color: darken($border, 25%);
  }
  &:hover {
    color: $color;
    background-color: $hover;
    border-color: darken($border, 12%);
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: darken($background, 10%);
    border-color: darken($border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: darken($background, 17%);
      border-color: darken($border, 25%);
    }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
      background-color: $background;
      border-color: $border;
    }
  }

  .badge {
    color: $background;
    background-color: $color;
  }
}

// Button sizes
@mixin button-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius) {
  padding: $padding-vertical $padding-horizontal;
  font-size: $font-size;
  line-height: $line-height;
  border-radius: $border-radius;
}

// clearfix
@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

// Chevron
@mixin chevron-size($chevron-size: 10px, $border-width: 2px) {
  height: $chevron-size;
  width: $chevron-size;
  border-width: $border-width $border-width 0 0;
}

@mixin clearList() {
    list-style: none;
    margin: 0;
    padding: 0;
}

@mixin ic() {
    font-family: 'beaches-icons';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin horizontalDivider($r, $g, $b, $color){
	background: $color;
	background: linear-gradient(to right, rgba($r, $g, $b, .0) 0%, rgba($r, $g, $b, 1) 20%, rgba($r, $g, $b, 1) 70%, rgba($r, $g, $b, .0) 100%);
}

@mixin rounded($rad:10px){
  -moz-border-radius: $rad;
  -webkit-border-radius: $rad;
  border-radius: $rad;
}
@mixin verticalGradient ($startColor: #e3e3e3, $endColor: #f0f0f0) {
  background-color: $endColor;
  background-image: -moz-linear-gradient(top, $startColor, $endColor);
  background-image: -ms-linear-gradient(top, $startColor, $endColor);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from($startColor), to($endColor));
  background-image: -webkit-linear-gradient(top, $startColor, $endColor);
  background-image: -o-linear-gradient(top, $startColor, $endColor);
  background-image: linear-gradient(top, $startColor, $endColor);
  background-repeat: repeat-x;
  //Filters Needed for IE(Do Not Remove)
  filter:progid:DXImageTransform.Microsoft.Gradient(GradientType=0, startColorstr='#{ie-hex-str($startColor)}', endColorstr='#{ie-hex-str($endColor)}');
  -ms-filter:"progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($startColor)}', endColorstr='#{ie-hex-str($endColor)}')";
}
@mixin horizontalGradient($left, $right){
  background: $left;
  background: -moz-linear-gradient(left, $left 0%, $right 100%);
  background: -webkit-gradient(linear, left top, right top, color-stop(0%,$left), color-stop(100%,$right));
  background: -webkit-linear-gradient(left, $left 0%,$right 100%);
  background: -o-linear-gradient(left, $left 0%,$right 100%);
  background: -ms-linear-gradient(left, $left 0%,$right 100%);
  background: linear-gradient(to right, $left 0%,$right 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=#{$left}, endColorstr=#{$right},GradientType=1 );
}

@mixin box-sizing($boxmodel) {
  -webkit-box-sizing: $boxmodel;
  -moz-box-sizing: $boxmodel;
  box-sizing: $boxmodel;
}
@mixin font-resize($font-size, $line-height) {
  font-size: $font-size;
  line-height: $line-height;
}
@mixin text-stroke($color,$size){
  -webkit-text-stroke: $size $color; 
  text-stroke: $size $color; 
}
@mixin font-resize($font-size, $line-height) {
  font-size: $font-size;
  line-height: $line-height;
}
@mixin drop-shadow(
  $x-axis: 0.2rem,
  $y-axis: 0.2rem,
  $blur: 0.6rem,
  $spread: 0,
  $opacity: 0.25
) {
  box-shadow: $x-axis $y-axis $blur $spread rgba(0, 0, 0, $opacity);
}