.sprite--eu-flag {
    display: inline-block;
    vertical-align: middle;
    background-position: calc(-42px / 2) calc(0px / 2);
    width: calc(46px / 2);
    height: calc(30px / 2);
}
.sprite--uk-flag {
    display: inline-block;
    vertical-align: middle;
    background-position: calc(0px / 2) calc(-42px / 2);
    width: calc(46px / 2);
    height: calc(30px / 2);
}
.sprite--world-icon {
    display: inline-block;
    vertical-align: middle;
    background-position: calc(0px / 2) calc(0px / 2);
    width: calc(40px / 2);
    height: calc(40px / 2);
}

.sprite.spriteLoad {
    background-image: url('//cdn.sandals.com/beaches/v12/images/globals/sprite/desktop-sprite.png');
}

.sprite {
    background-size: calc(88px / 2) calc(72px / 2);
}
