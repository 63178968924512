/*
// Variables
// ------------------------------------------------ */

/*==== Paths ====*/
$assets-path           : '/assets';
$cdn-url               : "https:://cdn.sandals.com/beaches/v12"; // Production
$path: (
  img                  : '#{$cdn-url}/images',
  images               : '#{$cdn-url}/images',
  fonts                : '#{$assets-path}/fonts',
  svg                  : '#{$assets-path}/svg'
);

/*==== Scafolding ====*/
$body-bg               : #f5f5f5;
// $link-color            : color(grey, darker-2) !default;
$link-color            : #fff !default;
$link-hover-color      : lighten($link-color, 15%) !default;
$link-hover-decoration : none !default;
$mm-height             : 6.6rem; // Main Menu
$mm-sub-height         : 4rem; // Main Menu

$spacer                : 1rem !default;
$radius: (
  sm                   : .3rem,
  md                   : .5rem,
  lg                   : 1rem
);

$z-layers: (
  bottomless-pit       : -9999,
  before               : 0,
  default              : 1,
  after                : 2,
  header               : 10,
  dropdown             : 3000,
  overlay              : 4000,
  modal                : 4001,
  drama                : 9999
);

$transition-duration   : .3s;

//==== Components ====//
$padding: (
  base: (
    vertical           : 6px,
    horizontal         : 12px
  ),
  lg: (
    vertical           : 10px,
    horizontal         : 16px
  ),
  md: (
    vertical           : 7px,
    horizontal         : 14px
  ),
  sm: (
    vertical           : 5px,
    horizontal         : 10px
  ),
  xs: (
    vertical           : 1px,
    horizontal         : 5px
  )
);


$container-tablet:        744px;

$container-desktop:       970px;

$container-large-desktop: 1164px;

//==== Forms ====//
$input-padding-x       : 1rem !default;
$input-padding-y       : .8rem !default;
$input-line-height     : 1.25 !default;
$input-font-size       : 1.8rem !default;

/*==== Typography ====*/
$font-cache                    : 'v=#{unique-id()}';
$font-icons-name               : 'beaches-icons';
$font-os-light-name            : 'OpenSans-Light';
$font-os-regular-name          : 'OpenSans-Regular';
$font-os-semibold-name         : 'OpenSans-Semibold';
$font-os-bold-name             : 'OpenSans-Bold';
$font-os-сondensed-light-name  : 'Open Sans Condensed Light';
$font-os-сondensed-bold-name   : 'Open Sans Condensed Bold';
$font-bodoni-book-name         : 'bodoni-book';
$font-bodoni-bold-name         : 'bodoni-bold';
$font-bodoni-book-italic-name  : 'bodoni-bookIta';
$font-steelfish-rg-name        : 'SteelfishRg-Regular';
$font-nunito-bold-name         : 'Nunito Bold';
$font-nunito-extra-bold-name   : 'Nunito Extra Bold';
$font-nunito-semibold-name     : 'Nunito Semi Bold';
$font-playfair-regular-name    : 'PlayfairDisplay-Regular';
$font-playfair-italic-name     : 'PlayfairDisplay-Italic';
$font-bebasReg                 : 'bebas_modregular';
$font-arial                    : Arial, Helvetica, sans-serif !default;
$font-os-light                 : $font-os-light-name !default;
$font-os-regular               : $font-os-regular-name !default;
$font-good-foot-regular-name: "GoodFoot-Regular";
$font-os-semibold              : $font-os-semibold-name !default;
$font-os-bold                  : $font-os-bold-name !default;
$font-os-сondensed-light       : $font-os-сondensed-light-name !default;
$font-os-сondensed-bold        : $font-os-сondensed-bold-name !default;
$font-bodoni-book              : $font-bodoni-book-name !default;
$font-bodoni-bold              : $font-bodoni-bold-name !default;
$font-bodoni-book-italic       : $font-bodoni-book-italic-name !default;
$font-steelfish-rg             : $font-steelfish-rg-name !default;
$font-nunito-bold              : $font-nunito-bold-name !default;
$font-nunito-extra-bold        : $font-nunito-extra-bold-name !default;
$font-nunito-semibold          : $font-nunito-semibold-name !default;
$font-playfair-regular         : $font-playfair-regular-name!default;
$font-playfair-italic          : $font-playfair-italic-name!default;
$font-good-foot-regular: $font-good-foot-regular-name !default;

/*===== Font Families  =====*/
$font-family: (
  arial : $font-arial,
  opensans-light : $font-os-light,
  opensans-regular : $font-os-regular,
  opensans-semibold : $font-os-semibold,
  opensans-bold : $font-os-bold,
  opensans-condensed-light : $font-os-сondensed-light,
  opensans-condensed-bold : $font-os-сondensed-bold,
  bodoni-book : $font-bodoni-book,
  bodoni-bold : $font-bodoni-bold,
  bodoni-book-italic : $font-bodoni-book-italic,
  steelfish : $font-steelfish-rg,
  nunito-bold : $font-nunito-bold,
  nunito-extra-bold : $font-nunito-extra-bold,
  nunito-semibold : $font-nunito-semibold,
  playfair-regular: $font-playfair-regular,
  good-foot-regular: $font-good-foot-regular,
  playfair-italic: $font-playfair-italic
);

// Icons
$font-icons            : $font-icons-name, sans-serif !default;

// Miscellaneous
$font-base             : $font-os-regular !default;
$font-color            : color(grey, darker-2) !default;
$font-weight           : 400 !default;
$font-size             : 1.5rem !default;
$line-height           : 2rem !default;

$font-size-h1          : 10rem !default;
$font-size-h2          : 8.5rem !default;
$font-size-h3          : 6.5rem !default;
$font-size-h4          : 5rem !default;
$font-size-h5          : 3.6rem !default;
$font-size-h6          : 2rem !default;

$headings-margin       : (1rem / 2) !default;
$headings-font-family  : $font-nunito-bold  !default;
$headings-font-weight  : 400 !default;
$headings-line-height  : 1.1 !default;
$headings-color        : inherit !default;

/*==== Breakpoints ====*/
$breakpoints: (
  xsmall               : 320px,
  small                : 375px,
  medium               : 425px,
  large                : 768px, // tablet
  xlarge               : 1024px, // desktop
  xxl                  : 1400px,
  min-xtra             : 1439px,
  xtra                 : 1440px,
  2k                   : 2048px,
  xs-max               : 767px,
  sm-min               : 768px, // tablet-min
  sm-md-min            : 992px,
  sm-max               : 1023px, // tablet-max
  md-min               : 1024px, // desktop-min
  md-max               : 1199px,
  lg-min               : 1200px,
  mobile-map-max       : 730px,
  image-max            : 1920px
);

/*==== Grid System ====*/
$container-width       : 1180px;
$width-xs              : 30 !default;
$width-sm              : 48 !default;
$width-md              : 62 !default;
$width-lg              : 75 !default;
$width-xl              : 80 !default;

$viewport-xs           : map-get($breakpoints, xsmall) !default;
$viewport-sm           : map-get($breakpoints, small) !default;
$viewport-md           : map-get($breakpoints, medium) !default;
$viewport-lg           : map-get($breakpoints, large) !default;
$viewport-xl           : map-get($breakpoints, xlarge) !default;
$viewport-xxl          : map-get($breakpoints, xxl) !default;
$viewport-lg-min       : map-get($breakpoints, lg-min) !default;
$icon-button-arrow-next: "\e93b";