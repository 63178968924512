
/*
// Font Families
// ------------------------------------------------ */

// Font Icons ===================================
@font-face {
    font-family: $font-icons-name;
    src: url('#{map-get($path, fonts)}/#{$font-icons-name}/fonts/#{$font-icons-name}.woff?#{$font-cache}') format('woff');
    src: url('#{map-get($path, fonts)}/#{$font-icons-name}/fonts/#{$font-icons-name}.woff?#{$font-cache}') format('woff'),
         url('#{map-get($path, fonts)}/#{$font-icons-name}/fonts/#{$font-icons-name}.ttf?#{$font-cache}') format('truetype'),
         url('#{map-get($path, fonts)}/#{$font-icons-name}/fonts/#{$font-icons-name}.eot?#{$font-cache}#iefix') format('embedded-opentype'),
         url('#{map-get($path, fonts)}/#{$font-icons-name}/fonts/#{$font-icons-name}.svg?#{$font-cache}#sandals') format('svg');
    font-weight: normal;
    font-style: normal;
}

// SteelfishRg-Regular ====================================
@font-face {
    font-family: $font-steelfish-rg-name;
    src: url('#{map-get($path, fonts)}/Steelfish/SteelfishRg-Regular.woff?#{$font-cache}') format('woff');
    src: url('#{map-get($path, fonts)}/Steelfish/SteelfishRg-Regular.woff?#{$font-cache}') format('woff'),
         url('#{map-get($path, fonts)}/Steelfish/SteelfishRg-Regular.ttf?#{$font-cache}') format('truetype'),
         url('#{map-get($path, fonts)}/Steelfish/SteelfishRg-Regular.eot?#{$font-cache}#iefix') format('embedded-opentype'),
         url('#{map-get($path, fonts)}/Steelfish/SteelfishRg-Regular.svg?#{$font-cache}#SteelfishRg-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

// Bodoni Book Regular ====================================
@font-face {
    font-family: $font-bodoni-book-name;
    src: url('#{map-get($path, fonts)}/Bodoni/Book/bodoni-book.woff?#{$font-cache}') format('woff');
    src: url('#{map-get($path, fonts)}/Bodoni/Book/bodoni-book.woff?#{$font-cache}') format('woff'),
         url('#{map-get($path, fonts)}/Bodoni/Book/bodoni-book.ttf?#{$font-cache}') format('truetype'),
         url('#{map-get($path, fonts)}/Bodoni/Book/bodoni-book.eot?#{$font-cache}#iefix') format('embedded-opentype'),
         url('#{map-get($path, fonts)}/Bodoni/Book/bodoni-book.svg?#{$font-cache}#bodoni-book') format('svg');
    font-weight: normal;
    font-style: normal;
}

// Bodoni Book Bold ====================================
@font-face {
    font-family: $font-bodoni-bold-name;
    src: url('#{map-get($path, fonts)}/Bodoni/Bold/bodoni-bold.woff?#{$font-cache}') format('woff');
    src: url('#{map-get($path, fonts)}/Bodoni/Bold/bodoni-bold.woff?#{$font-cache}') format('woff'),
         url('#{map-get($path, fonts)}/Bodoni/Bold/bodoni-bold.ttf?#{$font-cache}') format('truetype'),
         url('#{map-get($path, fonts)}/Bodoni/Bold/bodoni-bold.eot?#{$font-cache}#iefix') format('embedded-opentype'),
         url('#{map-get($path, fonts)}/Bodoni/Bold/bodoni-bold.svg?#{$font-cache}#bodoni-bold') format('svg');
    font-weight: normal;
}

// Bodoni Book Italic  ====================================
@font-face {
    font-family: $font-bodoni-book-italic-name;
    src: url('#{map-get($path, fonts)}/Bodoni/BookItalic/bodoni-bookIta.woff?#{$font-cache}') format('woff');
    src: url('#{map-get($path, fonts)}/Bodoni/BookItalic/bodoni-bookIta.woff?#{$font-cache}') format('woff'),
         url('#{map-get($path, fonts)}/Bodoni/BookItalic/bodoni-bookIta.ttf?#{$font-cache}') format('truetype'),
         url('#{map-get($path, fonts)}/Bodoni/BookItalic/bodoni-bookIta.eot?#{$font-cache}#iefix') format('embedded-opentype'),
         url('#{map-get($path, fonts)}/Bodoni/BookItalic/bodoni-bookIta.svg?#{$font-cache}#bodoni-bookIta') format('svg');
    font-weight: normal;
    font-style: normal;
}

// Open Sans Regular ====================================
@font-face {
    font-family: $font-os-regular-name;
    src: url('#{map-get($path, fonts)}/OpenSans/Regular/#{$font-os-regular-name}.woff?#{$font-cache}') format('woff');
    src: url('#{map-get($path, fonts)}/OpenSans/Regular/#{$font-os-regular-name}.woff?#{$font-cache}') format('woff'),
         url('#{map-get($path, fonts)}/OpenSans/Regular/#{$font-os-regular-name}.ttf?#{$font-cache}') format('truetype'),
         url('#{map-get($path, fonts)}/OpenSans/Regular/#{$font-os-regular-name}.eot?#{$font-cache}#iefix') format('embedded-opentype'),
         url('#{map-get($path, fonts)}/OpenSans/Regular/#{$font-os-regular-name}.svg?#{$font-cache}#sandals') format('svg');
    font-weight: normal;
    font-style: normal;
}

// Open Sans Light ====================================
@font-face {
    font-family: $font-os-light-name;
    src: url('#{map-get($path, fonts)}/OpenSans/Light/#{$font-os-light-name}.woff?#{$font-cache}') format('woff');
    src: url('#{map-get($path, fonts)}/OpenSans/Light/#{$font-os-light-name}.woff?#{$font-cache}') format('woff'),
         url('#{map-get($path, fonts)}/OpenSans/Light/#{$font-os-light-name}.ttf?#{$font-cache}') format('truetype'),
         url('#{map-get($path, fonts)}/OpenSans/Light/#{$font-os-light-name}.eot?#{$font-cache}#iefix') format('embedded-opentype'),
         url('#{map-get($path, fonts)}/OpenSans/Light/#{$font-os-light-name}.svg?#{$font-cache}#sandals') format('svg');
    font-weight: normal;
    font-style: normal;
}

// Open Sans SemiBold ====================================
@font-face {
    font-family: $font-os-semibold-name;
    src: url('#{map-get($path, fonts)}/OpenSans/Semibold/#{$font-os-semibold-name}.woff?#{$font-cache}') format('woff');
    src: url('#{map-get($path, fonts)}/OpenSans/Semibold/#{$font-os-semibold-name}.woff?#{$font-cache}') format('woff'),
         url('#{map-get($path, fonts)}/OpenSans/Semibold/#{$font-os-semibold-name}.ttf?#{$font-cache}') format('truetype'),
         url('#{map-get($path, fonts)}/OpenSans/Semibold/#{$font-os-semibold-name}.eot?#{$font-cache}#iefix') format('embedded-opentype'),
         url('#{map-get($path, fonts)}/OpenSans/Semibold/#{$font-os-semibold-name}.eot?#{$font-cache}');
    font-weight: normal;
    font-style: normal;
}

// Open Sans Bold ====================================
@font-face {
    font-family: $font-os-bold-name;
    src: url('#{map-get($path, fonts)}/OpenSans/Bold/#{$font-os-bold-name}.woff?#{$font-cache}') format('woff');
    src: url('#{map-get($path, fonts)}/OpenSans/Bold/#{$font-os-bold-name}.woff?#{$font-cache}') format('woff'),
         url('#{map-get($path, fonts)}/OpenSans/Bold/#{$font-os-bold-name}.ttf?#{$font-cache}') format('truetype'),
         url('#{map-get($path, fonts)}/OpenSans/Bold/#{$font-os-bold-name}.eot?#{$font-cache}'),
         url('#{map-get($path, fonts)}/OpenSans/Bold/#{$font-os-bold-name}.eot?#{$font-cache}#iefix') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
}

// Open Sans Condensed Light ====================================
@font-face {
    font-family: $font-os-сondensed-light-name;
    src: url('#{map-get($path, fonts)}/OpenSans/Condensed/Light/OpenSansCondensed-Light.woff?#{$font-cache}') format('woff');
    src: url('#{map-get($path, fonts)}/OpenSans/Condensed/Light/OpenSansCondensed-Light.woff?#{$font-cache}') format('woff'),
         url('#{map-get($path, fonts)}/OpenSans/Condensed/Light/OpenSansCondensed-Light.ttf?#{$font-cache}') format('truetype'),
         url('#{map-get($path, fonts)}/OpenSans/Condensed/Light/OpenSansCondensed-Light.eot?#{$font-cache}#iefix') format('embedded-opentype'),
         url('#{map-get($path, fonts)}/OpenSans/Condensed/Light/OpenSansCondensed-Light.eot?#{$font-cache}');
    font-weight: normal;
    font-style: normal;
}

// Open Sans Condensed Bold ====================================
@font-face {
    font-family: $font-os-сondensed-bold-name;
    src: url('#{map-get($path, fonts)}/OpenSans/Condensed/Bold/open-sans-condensed.woff?#{$font-cache}') format('woff');
    src: url('#{map-get($path, fonts)}/OpenSans/Condensed/Bold/open-sans-condensed.woff2?#{$font-cache}') format('woff2'),
         url('#{map-get($path, fonts)}/OpenSans/Condensed/Bold/open-sans-condensed.woff?#{$font-cache}') format('woff'),
         url('#{map-get($path, fonts)}/OpenSans/Condensed/Bold/open-sans-condensed.ttf?#{$font-cache}') format('truetype'),
         url('#{map-get($path, fonts)}/OpenSans/Condensed/Bold/open-sans-condensed.eot?#{$font-cache}#iefix') format('embedded-opentype'),
         url('#{map-get($path, fonts)}/OpenSans/Condensed/Bold/open-sans-condensed.svg?#{$font-cache}#sandals') format('svg');
    font-weight: normal;
    font-style: normal;
}

// Nunito Bold ====================================
@font-face {
    font-family: $font-nunito-bold-name;
    src: url('#{map-get($path, fonts)}/Nunito/Bold/Nunito-Bold.woff?#{$font-cache}') format('woff');
    src: url('#{map-get($path, fonts)}/Nunito/Bold/Nunito-Bold.woff2?#{$font-cache}') format('woff2'),
         url('#{map-get($path, fonts)}/Nunito/Bold/Nunito-Bold.woff?#{$font-cache}') format('woff'),
         url('#{map-get($path, fonts)}/Nunito/Bold/Nunito-Bold.ttf?#{$font-cache}') format('truetype'),
         url('#{map-get($path, fonts)}/Nunito/Bold/Nunito-Bold.eot?#{$font-cache}#iefix') format('embedded-opentype'),
         url('#{map-get($path, fonts)}/Nunito/Bold/Nunito-Bold.svg?#{$font-cache}#sandals') format('svg');
    font-weight: normal;
    font-style: normal;
}

// Nunito Extra Bold ==============================
@font-face {
    font-family: $font-nunito-extra-bold-name;
    src: url('#{map-get($path, fonts)}/Nunito/ExtraBold/Nunito-ExtraBold.woff?#{$font-cache}') format('woff');
    src: url('#{map-get($path, fonts)}/Nunito/ExtraBold/Nunito-ExtraBold.woff2?#{$font-cache}') format('woff2'),
         url('#{map-get($path, fonts)}/Nunito/ExtraBold/Nunito-ExtraBold.woff?#{$font-cache}') format('woff'),
         url('#{map-get($path, fonts)}/Nunito/ExtraBold/Nunito-ExtraBold.ttf?#{$font-cache}') format('truetype'),
         url('#{map-get($path, fonts)}/Nunito/ExtraBold/Nunito-ExtraBold.eot?#{$font-cache}#iefix') format('embedded-opentype'),
         url('#{map-get($path, fonts)}/Nunito/ExtraBold/Nunito-ExtraBold.svg?#{$font-cache}#sandals') format('svg');
    font-weight: normal;
    font-style: normal;
}

// Nunito SemiBold ==============================
@font-face {
    font-family: $font-nunito-semibold-name;
    src: url('#{map-get($path, fonts)}/Nunito/SemiBold/Nunito-SemiBold.woff?#{$font-cache}') format('woff');
    src: url('#{map-get($path, fonts)}/Nunito/SemiBold/Nunito-SemiBold.woff2?#{$font-cache}') format('woff2'),
         url('#{map-get($path, fonts)}/Nunito/SemiBold/Nunito-SemiBold.woff?#{$font-cache}') format('woff'),
         url('#{map-get($path, fonts)}/Nunito/SemiBold/Nunito-SemiBold.ttf?#{$font-cache}') format('truetype'),
         url('#{map-get($path, fonts)}/Nunito/SemiBold/Nunito-SemiBold.eot?#{$font-cache}#iefix') format('embedded-opentype'),
         url('#{map-get($path, fonts)}/Nunito/SemiBold/Nunito-SemiBold.svg?#{$font-cache}#sandals') format('svg');
    font-weight: normal;
    font-style: normal;
}

// PlayFair Display Regular ==================
@font-face {
    font-family: $font-playfair-regular-name;
    src: url('#{map-get($path, fonts)}/PlayFairDisplay/PlayfairDisplay-Regular.otf?#{$font-cache}') format('otf');
    font-weight: normal;
    font-style: normal;
}

// BebasMod-Regular ====================================
@font-face {
    font-family: $font-bebasReg;
    src: url('#{map-get($path, fonts)}/bebasRegular/bebasmod-regular-webfont.woff?#{$font-cache}') format('woff');
    src: url('#{map-get($path, fonts)}/bebasRegular/bebasmod-regular-webfont.woff?#{$font-cache}') format('woff'),
         url('#{map-get($path, fonts)}/bebasRegular/bebasmod-regular-webfont.ttf?#{$font-cache}') format('truetype'),
         url('#{map-get($path, fonts)}/bebasRegular/bebasmod-regular-webfont.eot?#{$font-cache}#iefix') format('embedded-opentype'),
         url('#{map-get($path, fonts)}/bebasRegular/bebasmod-regular-webfont.svg?#{$font-cache}#SteelfishRg-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

// Good-foot
@font-face {
    font-family: $font-good-foot-regular;
    src: url('#{map-get($path, fonts)}/good-foot/GoodFoot-Regular.woff?#{$font-cache}') format('woff');
    src: url('#{map-get($path, fonts)}/good-foot/GoodFoot-Regular.woff?#{$font-cache}') format('woff'),
         url('#{map-get($path, fonts)}/good-foot/GoodFoot-Regular.ttf?#{$font-cache}') format('truetype'),
         url('#{map-get($path, fonts)}/good-foot/GoodFoot-Regular.eot?#{$font-cache}#iefix') format('embedded-opentype'),
         url('#{map-get($path, fonts)}/good-foot/GoodFoot-Regular.svg?#{$font-cache}#SteelfishRg-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}
