.mfp-wrap {
    &.video-popup {
        .mfp-close {
            top: -3rem;
            right: 0;
            color: color(shades, white);
            cursor: pointer;

            @media (min-width: 992px) {
                right: -3rem;
            }
        }
    }

    .mfp-container {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
    }

    .mfp-close {
        top: 1rem;
        right: 1rem;
        width: 2rem;
        height: 2rem;
        padding: 0;
        font-size: 2rem;
        color: color(shades, black);
        opacity: 1;

        &:before {
            content: "\e930";
            font-family: $font-icons-name;
            line-height: 1;
            vertical-align: top;
        }
    }

    .simple-modal {
        position: relative;
        max-width: 59rem;
        margin: 2rem auto;
        padding: 4rem 2rem;
        color: color(grey, dark);
        background-color: color(shades, white);
        border-radius: .4rem;

        @media (min-width: 992px) {
            max-width: 74.6rem;
            padding: 5rem 3rem;
        }

        &.dark {
            color: color(shades, white);
            background-color: color(shades, black);

            .mfp-close {
                color: color(shades, white);
            }
        }
    }
}
