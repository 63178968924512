.video__overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1200;
    display: -webkit-flex;
    display:     -ms-flex;
    display:         flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.8);
}



.video__wrapper {
    position: relative;
    width: 96%;
    max-width: 900px;

    @include respond-to-max(xs-max) {
        margin-top: 4rem;
    }
}

.video__close {
    position: absolute;
    top: -4rem;
    right: -4rem;
    cursor: pointer;

    @media screen and (max-width: 1000px) {
        right: 0;
    }

    .icon-close {
        font-size: 2rem;
        color: color(shades, white);
    }
}

.video__iframe-wrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 56.25%;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
