/*
// Base Stylings
// ------------------------------------------------ */

/*==== General ====*/
html.has-open-menu,
html.has-open-resorts {

    @media (max-width: #{map-get($breakpoints, xs-max)}) {
        overflow: hidden;
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        pointer-events: none;
    }
}
html {
  font-size: 10px;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0,0,0,0);

  // @include respond-to(medium) {
  //   font-size: $font-size;
  // }
  // @include respond-to(large) {
  //   font-size: $font-size;
  // }
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
body {
  font: $font-weight #{$font-size}/#{$line-height} $font-base;
  color: $font-color;
  background: $body-bg;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  padding-top: 4rem;
  // @include respond-to(sm-min) {
  //     padding-top: 4rem;
  // }

  &.has-open-menu,
  &.has-open-resorts {
      overflow: hidden;

      @media (max-width: #{map-get($breakpoints, xs-max)}) {
          position: fixed;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          pointer-events: none;
          background: color(grey, darker-3);

          .header ~ div {
              opacity: 0;
          }
      }
  }

  &.has-mm {
    padding-top: $mm-height;

    @include respond-to(large) {
      padding-top: 0;
    }
  }
}

main.main {
  @include respond-to-max(xs-max) {
      padding-top: 1rem;
  }
  @include respond-to(sm-min) {
      padding-top: 6.8rem;
  }

  @media (min-width: 767px) and (max-width: 768px) {
    padding-top: 10rem;
  }

  @include respond-to(md-min) {
      padding-top: 16rem;
  }
  @include respond-to(xlarge) {
      padding-top: 8.8rem;
  }
}

a {
  color: $link-color;
  text-decoration: none;
  transition: all $transition-duration ease;

  &:hover,
  &:focus,
  &:active {
    color: $link-color;
    text-decoration: $link-hover-decoration;
  }
}
a[href^="tel"]{
  color: inherit;
  text-decoration: none;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    position: relative;
    padding-left: 1.5rem;

    &::before {
      position: absolute;
      top: 50%;
      left: 0;
      background-color: color(blue);
      border-radius: 50%;
      font-size: 20px;
      transform: translateY(-50%);
      content: '';
      width: .25em;
      height: .25em;
    }
  }
}
ol {
  margin: 0;
  padding: 0;
  list-style: none;
  counter-reset: ordered-list;

  li {
    &::before {
      // position: absolute;
      // top: 50%;
      // left: 0;
      margin-right: .3rem;
      color: color(blue);
      counter-increment: ordered-list;
      content: counter(ordered-list) ".";
    }
  }
}

button {
  cursor: pointer;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

.show-xs {
  @include respond-to-max(xs-max) {
    display: block;
  }
}

.hidden-xs {
  @include respond-to-max(xs-max) {
    display: none;
  }
}

.progressive {
  position: absolute;
  display: block;
  overflow: hidden;
  outline: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &:not(.replace) {
    cursor: default;
  }

  img {
    display: block;
    max-width: none;
    height: auto;
    border: 0 none;
    position: absolute;
    object-fit: cover;
    object-position: center center;
    width: 100%;

    &.preview {
      filter: blur(2vw);
      transform: scale(1.05);
    }

    // &.reveal {
      // position: absolute;
      // left: 0;
      // top: 0;
      // will-change: transform, opacity;
      // animation: reveal 500ms linear;
      // @keyframes reveal {
        // 0% { opacity: 0; }
        // 100% { opacity: 1; }
      // }
    // }
  }

  &.background {
    z-index: -1;

    img {
      height: 100%;
    }
  }

  &.objectFit-backgroundImg {
    background-size: cover;
    background-position: center;

    img {
      opacity: 0;
    }
  }
}
.main--offset {
    @include respond-to-max(xs-max) {
        padding-top: 0;
    }

    @include respond-to(sm-min) {
        padding-top: 6.8rem;
    }

    @include respond-to(md-min) {
        padding-top: 8.8rem;
    }
}

//FOR DEVELOPMENT PHASE USE ONLY, DO NOT MODIFY.
.development-img-overlay, .development-img-overlay-mobile {
    opacity: 0.5;
    position: absolute;
    top: 0;
    opacity: 1;
    display: none;
    z-index: 1100;
}
.development-img-overlay-mobile {
    width: 320px;
}
//EXAMPLE OF USAGE WITH DESIGNS (DESKTOP AT 1460PX, TABLET AT 768PX, MOBILE AT 320PX):
// <img src="/assets/beaches-select-rewards-DESKTOP.png" class="development-img-overlay hidden-xlarge-down"/>
// <img src="/assets/beaches-select-rewards-TABLET.png" class="development-img-overlay hidden-large-down hidden-xlarge-up"/>
// <img src="/assets/beaches-select-rewards-MOBILE.png" class="development-img-overlay-mobile hidden-large-up"/>
