/*
// Utilities
// ------------------------------------------------ */

/*==== General ====*/
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.pull-none {
  float: none;
}
.clearfix {
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

/*==== Postitioning ====*/
.center {
  margin: auto auto;
}
.txt-center {
  text-align: center;
}
.txt-right {
  text-align: right;
}
.txt-left {
  text-align: left;
}

/*==== Toggling content ====*/
.hide {
  display: none;
}
.show,
.display-block {
  display: block !important;
}
.invisible {
  visibility: hidden;
}
.visible {
  visibility: visible;
}
.muted {
  opacity: .7;
}
.no-flow {
  position: fixed;
  overflow: hidden;
  height: 100%;
  width: 100%;
}
.xflow {
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
}
.no-flow-x {
  overflow-x: hidden;
}
.visible-xs-inline-block {
  @include respond-to-max(xs-max) {
    display: inline-block;
  }
  @include respond-to(sm-min) {
    display: none;
  }
}

/*==== Positioning ====*/
.pa {
  position: absolute;
}
.affix {
  position: fixed;
}
.pr {
  position: relative;
}
.center {
  margin: auto auto;
}

/*==== Inline Block ====*/
.ib,
.inline-block {
  display: inline-block;
  vertical-align: middle;
}

/*==== Images ====*/
.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
  width: 100%;
}

/*==== Font Families ====*/
@each $key, $value in $font-family {
  .#{$key} {
    font-family: $value;
  }
}

/*==== Font Transforms ====*/
.uppercase {
  text-transform: uppercase;
}
.lowercase {
  text-transform: lowercase;
}
.capitalize {
  text-transform: capitalize;
}
.single-line {
  white-space: nowrap;
}
/*==== Font-Weight ====*/
.font-bold {
  font-weight: bold;
}
.font-normal {
  font-weight: 400;
}
.font-italic {
  font-style: italic;
}
.has-modal {
    overflow: hidden;
}
.color-dash {
  position: relative;
  &:after, &-before:before {
    display: inline-block;
    content: "";
    background-image: url("https://cdn.sandals.com/beaches/v12/images/globals/colorful-leaves.jpg");
    background-repeat: repeat;
    width: 100%;
    height: 4px;
    left: 0;
    position: absolute;
    @include respond-to(sm-min) {
      height: 8px;
    }
  }
  &:after {
    top: 100%;
  }
  &-before {
    position: relative;
    &:before {
      top: -4px;
      @include respond-to(sm-min) {
        top: -8px;
      }
    }
  }
}
.grey-leaves-background {
  background-image: url("https://cdn.sandals.com/beaches/v12/images/general/site-map/banner.jpg") !important;
  background-repeat: repeat !important;
}

.html-no-scroll {
  position: relative;
  overflow: hidden;

  body {
    overlow: hidden;
  }
}

.beaches-loader {

    .loader {
        display: block;
        margin: 0 auto;
    }


    &.inactive {
      display: none;
    }

    &.submitting-post {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin-top: 0;
      display: block;
      background-color: rgba(0,0,0,.75);
      z-index: 9999;
        .loader {
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        animation: fade-in .6s;
        transform: translateY(-50%);
        top: 50%;
        }
        .path {
            stroke: color(grey, lighter-1);
        }
    }
}
